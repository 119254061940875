<template>
    <click-to-edit
      v-model="scope.row.desc"
      placeholder="Внесите описание цели"
      :disabled="!isFieldAvailableForEdit(scope.row, 'desc')"
      style="width: 100%"
      type="textarea"
      @input="saveElementPropChange(scope.row, 'desc')"
    ></click-to-edit>
</template>

<script>

import ClickToEdit from "@/components/ClickToEdit";
import {ElementPropTableColumn} from "@/mixins/elementPropTableColumn";
export default {
  name: 'element-desc-table-column',
  components: {ClickToEdit},
  mixins: [ElementPropTableColumn],

  props: {},
  data() {
    return {

    }
  },
  computed: {},
  methods: {

  }
}

</script>

<style scoped lang="scss">
@import "@/assets/css/project-variables";

</style>
